import { Observable, BehaviorSubject } from 'rxjs';
import { MessageCenter, doLog } from '../event';
import * as socketStatus from '../const';
import { HUAWEI } from '../const';
function SocketteWebsocket(opt = {
    url: '',
    input: null,
}) {
    const { url, input } = opt;
    const connectionStatus = new BehaviorSubject(socketStatus.WEBSOCKET_INIT);
    const messages = new Observable((observer) => {
        let inputSubscription;
        let open = false;
        let forcedClose = false;
        const closed = () => {
            if (!open) {
                return;
            }
            connectionStatus.next(socketStatus.WEBSOCKET_CLOSED);
            open = false;
        };
        connectionStatus.next(socketStatus.WEBSOCKET_OPENING);
        const ws = new WebSocket(url);
        ws.onopen = () => {
            open = true;
            connectionStatus.next(socketStatus.WEBSOCKET_OPENED);
            inputSubscription = input?.subscribe((data) => {
                ws.send(data);
            });
        };
        ws.onmessage = (message) => {
            // add by tdd
            try {
                const res = JSON.parse(message.data);
                const { Cmd, requestId = '', taskID, resultCode, resultInfo } = res;
                const listenData = {
                    dataSource: HUAWEI,
                    taskID,
                    data: res,
                };
                // 将客户端指令转换为内部公共通知指令
                if (Cmd === 'sendPrinterData') {
                    if (resultCode === '0') {
                        listenData.cmd = 'notifyPrintResult';
                        listenData.success = true;
                    }
                    else if (resultCode === '1') {
                        listenData.cmd = 'notifyPrintError';
                        listenData.success = false;
                        listenData.error = {
                            code: resultCode,
                            message: resultInfo,
                        };
                    }
                }
                MessageCenter.listen(listenData);
                // 上报日志
                if (Cmd === 'sendPrinterData') {
                    const task = MessageCenter.getTaskById?.(requestId) || {};
                    doLog({
                        traceId: task.traceId || requestId,
                        dataSource: HUAWEI,
                        process: 'FRONT_END',
                        action: 'CN_PRINT_RESULT',
                        accountNo: '',
                        accountName: '',
                        bizCode: '',
                        docType: 'WAY_BILL',
                        printNode: 'NORMAL',
                        status: listenData.success ? 'success' : 'failed',
                        response: res,
                        errorCode: '20001',
                        errorMessage: '',
                        pageUrl: window.location.href,
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
            observer.next(message.data);
        };
        ws.onclose = (e) => {
            // prevent observer.complete() being called after observer.error(...)
            if (!open) {
                return;
            }
            closed();
            if (forcedClose) {
                connectionStatus.next(socketStatus.WEBSOCKET_COMPLETE);
                observer.complete();
            }
            else {
                connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
                observer.error(new Error(e.reason));
            }
        };
        ws.onerror = (err) => {
            closed();
            connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
            observer.error(err);
        };
        return () => {
            forcedClose = true;
            if (inputSubscription) {
                inputSubscription.unsubscribe();
            }
            if (open) {
                closed();
                ws.close();
            }
        };
    });
    return { messages, connectionStatus };
}
export default SocketteWebsocket;

import EventManager from './eventManager';
import { mixins, doLog } from './tools';
import { COMMENDCONFIG, CONFIGMESSAGETYPE, MESSAGETYPE, PRINTRESULTNOTIFY, FAILED, NOTIFYPRINTRESULT, NOTIFYPRINTERROR, } from './const';
const EVENTMANAGER = EventManager.Instance;
const MessageCenter = {
    listen: (result) => {
        const { messageType = null, type = null, cmd = '', dataSource = 'CAINIAO', taskStatus = '', key = '', } = result;
        const data = (result.data || {});
        let { taskID } = result;
        let targetCmd = cmd;
        if (dataSource === 'CAINIAO') {
            targetCmd = data.cmd;
            taskID = data.taskID;
        }
        if (data && data.pageSession && data.pageSession.pageUrl !== window.location.href) {
            return;
        }
        if (type === 1 && messageType === CONFIGMESSAGETYPE) {
            targetCmd = 'getConfigName';
        }
        if (type === 1 && messageType === MESSAGETYPE && data && data.configList) {
            targetCmd = 'getPrintConfigNameList';
        }
        if (targetCmd === PRINTRESULTNOTIFY) {
            targetCmd = NOTIFYPRINTRESULT;
            if (taskStatus && taskStatus === FAILED) {
                targetCmd = NOTIFYPRINTERROR;
            }
        }
        if (targetCmd == NOTIFYPRINTRESULT || targetCmd === NOTIFYPRINTERROR) {
            EVENTMANAGER.removeTaskCache(taskID || key);
            result.remaining = EVENTMANAGER.getTaskCacheSize();
        }
        if (targetCmd) {
            EVENTMANAGER.broadcast(targetCmd, result);
        }
        else {
            EVENTMANAGER.broadcast('getVersion', result);
        }
    },
    addEventListen: (name, handler) => {
        EVENTMANAGER.register(name, handler);
    },
    removeEventListen: (name, handler) => {
        EVENTMANAGER.unregister(name, handler);
    },
    clearTaskCache: () => {
        EVENTMANAGER.clearTaskCache();
    },
    getTaskCacheSize: () => {
        return EVENTMANAGER.getTaskCacheSize();
    },
    addTaskCache: (id, content) => {
        return EVENTMANAGER.addTaskCache(id, content);
    },
    removeTaskCache: (id) => {
        return EVENTMANAGER.removeTaskCache(id);
    },
    getTaskById: (id) => {
        const task = EVENTMANAGER.getTaskById(id);
        task && EVENTMANAGER.removeTaskCache(id);
        return task;
    },
};
export { mixins, COMMENDCONFIG, MessageCenter, EventManager, doLog };

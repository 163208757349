import { getUserInfo } from 'src/utils';
import { DOLOGHOST } from './const';
// 类扩展方法
export const mixins = function (obj, tmpMixins) {
    const newObj = obj;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newObj.prototype = Object.create(obj.prototype);
    Object.keys(tmpMixins).forEach((key) => {
        if (tmpMixins[key]) {
            newObj.prototype[key] = tmpMixins[key];
        }
    });
    return newObj;
};
export function getUrl() {
    const path = '/print/record/log';
    let env;
    if (location.hostname.indexOf('pre-') === 0 || location.hostname.indexOf('-stg') > 0) {
        env = 'pre';
    }
    else if (location.hostname.indexOf('daily.') === 0 ||
        location.hostname.indexOf('daily-') === 0 ||
        location.hostname.indexOf('.test') > 0) {
        env = 'daily';
    }
    else {
        env = 'online';
    }
    const currentHost = DOLOGHOST[env];
    return `${currentHost}${path}`;
}
export function doLog(params) {
    if (!window.CLOSE_LOG || window.CLOSE_LOG !== true) {
        const url = getUrl();
        const { accountNo, accountName, resCode, resName } = getUserInfo();
        console.log('cnprint/doLog', params);
        const data = Object.assign(params, {
            environment: collectEnvironment(),
            accountNo,
            accountName,
            resCode,
            resName,
        });
        fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
        }).catch((e) => {
            console.log(e);
        });
    }
}
export function collectEnvironment() {
    return {
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        screenAvailWidth: window.screen.availWidth,
        screenAvailHeight: window.screen.availHeight,
        appCodeName: navigator.appCodeName || '',
        appName: navigator.appName || '',
        appVersion: navigator.appVersion || '',
        deviceMemory: navigator.deviceMemory,
        hardwareConcurrency: navigator.hardwareConcurrency,
        language: navigator.language,
        languages: navigator.languages,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
    };
}

import { Observable, BehaviorSubject } from 'rxjs';
import { MessageCenter, doLog } from '../event';
import * as socketStatus from '../const';
import { XHS } from '../const';
function SocketteWebsocket(opt = {
    url: '',
    input: null,
}) {
    const { url, input } = opt;
    const connectionStatus = new BehaviorSubject(socketStatus.WEBSOCKET_INIT);
    const messages = new Observable((observer) => {
        let inputSubscription;
        let open = false;
        let forcedClose = false;
        const closed = () => {
            if (!open) {
                return;
            }
            connectionStatus.next(socketStatus.WEBSOCKET_CLOSED);
            open = false;
        };
        connectionStatus.next(socketStatus.WEBSOCKET_OPENING);
        const ws = new WebSocket(url);
        ws.onopen = () => {
            open = true;
            connectionStatus.next(socketStatus.WEBSOCKET_OPENED);
            inputSubscription = input?.subscribe((data) => {
                ws.send(data);
            });
        };
        ws.onmessage = (message) => {
            try {
                const res = JSON.parse(message.data);
                const { cmd, printer, taskID = '', status, taskStatus, printStatus = [] } = res;
                const listenData = {
                    dataSource: XHS,
                    taskID,
                    data: res,
                    printer,
                };
                // 将客户端指令转换为内部公共通知指令
                if (cmd === 'notifyPrintResult' &&
                    (taskStatus === 'rendered' || taskStatus === 'printed')) {
                    listenData.success = true;
                    listenData.cmd = 'notifyPrintResult';
                }
                if ((cmd === 'print' && status !== 'success') ||
                    (cmd === 'notifyPrintResult' && taskStatus === 'failed')) {
                    listenData.cmd = 'notifyPrintError';
                    listenData.success = false;
                    listenData.error = {
                        code: '-',
                        message: printStatus?.[0]?.msg,
                    };
                }
                MessageCenter.listen(listenData);
                if (cmd === 'print' || cmd === 'notifyPrintResult' || cmd === 'notifyPrintError') {
                    const task = MessageCenter.getTaskById?.(taskID) || {};
                    doLog({
                        traceId: task.traceId || taskID,
                        dataSource: XHS,
                        process: 'FRONT_END',
                        action: 'CN_PRINT_RESULT',
                        accountNo: '',
                        accountName: '',
                        bizCode: '',
                        docType: 'WAY_BILL',
                        printNode: 'NORMAL',
                        status: status || listenData.success ? 'success' : 'failed',
                        response: res,
                        errorCode: '20001',
                        errorMessage: '',
                        pageUrl: window.location.href,
                    });
                }
            }
            catch (e) {
                console.log(e);
            }
            observer.next(message.data);
        };
        ws.onclose = (e) => {
            // prevent observer.complete() being called after observer.error(...)
            if (!open) {
                return;
            }
            closed();
            if (forcedClose) {
                connectionStatus.next(socketStatus.WEBSOCKET_COMPLETE);
                observer.complete();
            }
            else {
                connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
                observer.error(new Error(e.reason));
            }
        };
        ws.onerror = (err) => {
            closed();
            connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
            observer.error(err);
        };
        return () => {
            forcedClose = true;
            if (inputSubscription) {
                inputSubscription.unsubscribe();
            }
            if (open) {
                closed();
                ws.close();
            }
        };
    });
    return { messages, connectionStatus };
}
export default SocketteWebsocket;

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("CNUI"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "CNUI", "react-dom", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["cn-domain-print"] = factory(require("react"), require("CNUI"), require("react-dom"), require("lodash"));
	else
		root["cn-domain-print"] = factory(root["React"], root["CNUI"], root["ReactDOM"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__154__) {
return 